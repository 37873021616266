import React from "react"
import styled from 'styled-components';
import Layout from "../components/layout";
import TitleEffect from "../components/title-effect";
import logo from '../assets/icon.png';
import Separator from "../components/separator";
import { Link } from "gatsby";
import Meta from "../components/meta";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const Logo = styled.img`
  width: 37.5px;
  height: 103px;
`;

const Text = styled.div`
  text-align: center;
`;

const ShopLink = styled(Link)`
  text-align: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid white;
  &:hover {
    border-bottom-color: black;
    color: black;
  }
`;


// markup
const NotFoundPage = () => {
  return (
    <Layout disableInsta>
      <Meta title="Vous êtes perdu"/>
      <Container>
        <TitleEffect size={4} hide>Erreur 404</TitleEffect>
        <Separator size={14} />
        <Logo src={logo} />
        <Separator size={14} />
        <Text>Désolé, on dirait bien que cette page n'existe plus...</Text>
        <Separator size={48} />
        <ShopLink to="/products">Boutique</ShopLink>
      </Container>
    </Layout>);
}

export default NotFoundPage
